"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class APIResponesStatus {
    constructor(data, status, success = false) {
        this.success = success;
        this.status = status;
        this.data = data;
    }
}
exports.default = APIResponesStatus;
