"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        boxShadow: '1px 1px 3px 0px #e0e0e0b3',
        padding: '14px',
    },
    "@media (max-width: 960px)": {
        paper: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '6px',
        },
    },
    "@media (min-width: 960px)": {
        paper: {
            marginTop: '12px',
            marginLeft: '6px',
            marginRight: '6px',
            marginBottom: '6px',
        },
    },
    cardcontent: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    card: {
        display: 'flex',
        padding: '5px'
    },
    cardMediaLeft: {
        padding: '8px',
    },
    cardMediaCenter: {
        paddingLeft: '40px',
        paddingRight: '40px',
        textAlign: 'center'
    },
    cardMediaCenterMobile: {
        textAlign: 'right'
    },
    title: {
        paddingBottom: '6px',
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    Divider: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
    Address: {
        width: '70%',
        marginTop: theme.spacing(5),
        lineHeight: theme.spacing(5)
    },
    textInTable: {
        lineHeight: 1
    },
    section2: {
        margin: theme.spacing(2),
    },
    subtitle: {
        fontSize: '20px',
        color: '#BBBBBB',
        marginBottom: '-8px'
    },
    subtitleDetail: {
        fontSize: '20px',
        marginBottom: '-8px'
    },
    CompanyProfileMobile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));
exports.default = useStyles;
