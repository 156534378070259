"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HTTPMethodEnum_1 = require("common/Manager/Enumeration/HTTPMethodEnum");
const ServerConfig_1 = require("common/Config/ServerConfig");
class StrapiAuthRequest {
    constructor(identifier, password) {
        this.method = HTTPMethodEnum_1.HTTPMethodEnum.POST;
        this.identifier = identifier;
        this.password = password;
        this.url = `${ServerConfig_1.serverConfig.WEB_LABEL_ENDPOINT}/api/auth/local`;
    }
    makeQuery() {
        return {};
    }
    makeHeader() {
        return {};
    }
    makeBody() {
        return {
            "identifier": this.identifier,
            "password": this.password
        };
    }
}
exports.default = StrapiAuthRequest;
