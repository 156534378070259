"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    Address: {
        lineHeight: theme.spacing(5),
        padding: theme.spacing(2),
        color: 'rgb(15, 190, 215)'
    },
    section2: {
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: '14px',
    },
    paper2: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px"
    },
    "@media (max-width: 960px)": {
        paper: {
            padding: '25px 14px',
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '6px',
        },
    },
    "@media (min-width:960px)": {
        paper: {
            padding: '25px 30px',
            boxShadow: '1px 1px 3px 0px #e0e0e0b3',
            marginTop: '6px',
            marginBottom: '12px',
            marginRight: '6px',
        },
    },
    box_barcode: {
        display: "flex",
        justifyContent: "flex-end",
        "@media (max-width: 960px)": {
            justifyContent: "center",
        },
    },
    box_ref: {
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
        },
    },
    grid: {
        marginTop: theme.spacing(1, 0, 0, 0)
    },
    papertwo: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {},
    tab: {
        paddingLeft: '50px',
        color: 'rgb(15, 190, 215)'
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    imgIcon: {
        padding: theme.spacing(1),
        height: '70px'
    },
    FormControl: {
        paddingBottom: theme.spacing(7),
        width: '100%'
    },
    ref: {
        fontSize: '20px',
        paddingTop: '9px',
        paddingBottom: '13px',
        paddingRight: '10px',
        // textAlign:"center",
        fontFamily: 'DBHeaventRoundedMedv32',
        "@media (max-width: 960px)": {
            textAlign: "center"
        },
    },
    scan_barcode_text: {
        fontSize: '20px',
        fontFamily: 'DBHeaventRoundedMedv32',
        paddingBottom: '9px',
        "@media (max-width: 960px)": {
            paddingBottom: '4px',
            textAlign: "center"
        },
    },
    scan_qrcode_text: {
        fontSize: '20px',
        fontFamily: 'DBHeaventRoundedMedv32',
        paddingBottom: '10px',
        textAlign: "center",
        "@media (max-width: 960px)": {
            paddingBottom: '4px',
        },
    },
    box_scan_barcode: {
        marginTop: "15px",
        //  display:"flex",
        //  justifyContent:"center" ,
        //  background:"#dddddd"     ,
        marginLeft: 0,
        marginRight: 0,
        padding: 0
    },
    barcodeText: {
        // paddingTop:'-5px'
        lineHeight: '0.5',
        fontSize: '16px',
        textAlign: 'center'
    },
    label_ref: {
        padding: '7px 10px',
        fontSize: '34px',
        fontFamily: 'DBHeaventRoundedMedv32',
        backgroundColor: '#f2f3fa'
    },
    box_howtopayment: {
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex'
    },
    text_howtopayment: {
        lineHeight: 1.0,
        color: '#00bfd8',
        fontSize: '20px'
    },
    text_howtopayment_detail: {
        lineHeight: 0.9,
        fontSize: '18px',
        width: '130px',
        "@media (max-width: 960px)": {
            width: '100%'
        },
    },
    title: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    title_billpayment: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
        // paddingTop: '15px',
        paddingBottom: '15px',
        "@media (max-width: 410px)": {
            lineHeight: 0.9,
            paddingBottom: '20px',
        },
    },
    servicecharge: {
        color: '#000000',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
        lineHeight: 1,
    },
    box_paymentmethod: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paymentmethod_detail: {
        color: '#000000',
        marginTop: "-8px",
        fontSize: '20px',
        lineHeight: 1.1,
        whiteSpace: 'pre-line',
    },
    img_counterpay: {
        marginTop: '20px',
        width: '65px',
        height: '65px',
    },
    img_counterservice: {
        marginTop: '25px',
        // width:'65px',
        height: '50px',
        "@media (max-width: 960px)": {
            height: '35px',
            marginLeft: '8px',
            marginTop: '17px',
        },
    },
    paymentmethod_ref1: {
        color: '#00bfd8'
    },
    servicecharge_detail: {
        color: '#000000',
        fontSize: '20px',
        lineHeight: 0.8,
    },
    changePaychannelText: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '20px',
        color: '#00bfd8',
        display: 'flex',
        alignItems: 'center'
    },
    merchant_name: {
        fontSize: '20px',
        color: '#00bfd8',
    },
    labelStyle: {
        fontSize: '22px',
        span: { fontSize: '22px' }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        color: '#000000'
    },
    resize: {
        fontSize: '30px'
    },
    link: {
        cursor: 'pointer',
        fontFamily: 'DBHeaventRoundedv32',
    },
    link_button: {
        fontFamily: 'DBHeaventRoundedv32',
        background: ' none!important',
        border: 'none',
        padding: ' 0!important',
        fontSize: '20px',
        color: '#00bfd8',
        cursor: 'pointer'
    },
    barcode: {
        width: '200px'
    },
    provider_list: {
        fontSize: '19px',
        paddingBottom: '10px',
        "@media (max-width: 960px)": {
            fontSize: '18px',
        },
    },
    row_provider: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '10px'
    },
    row_img: {
        display: 'flex',
        alignItems: "center",
        marginRight: '14px'
    },
    box_time: {
        display: "flex",
        justifyContent: "space-between"
    }
}));
exports.default = style;
