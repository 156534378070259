"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => ({
    root: {
        "&$selected": {
            // backgroundColor: "#c4d8dc",
            borderLeft: "3px solid red",
        }
    },
    selected: {},
    list: {
        width: '100%'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    active: {
        backgroundColor: "red"
    },
    paper: {
        boxShadow: '1px 1px 3px 0px #e0e0e0b3',
        padding: '14px',
    },
    listItemText: {
        fontSize: "20px",
        color: "#364469"
    },
    "@media (max-width: 960px)": {
        paper: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '6px',
        },
    },
    "@media (min-width: 960px)": {
        paper: {
            marginTop: '12px',
            marginLeft: '6px',
            marginRight: '6px',
            marginBottom: '6px',
        },
    },
    title: {
        paddingBottom: '6px',
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    Divider: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
}));
exports.default = useStyles;
