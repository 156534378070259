"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
// import useStyles from './style'
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    Address: {
        lineHeight: theme.spacing(5),
        padding: theme.spacing(2),
        color: 'rgb(15, 190, 215)'
    },
    section2: {
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: '14px',
    },
    "@media (max-width: 720px)": {
        paper: {
            padding: '25px 14px',
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
        },
    },
    "@media (min-width:720px)": {
        paper: {
            padding: '25px 30px',
            marginTop: '6px',
            marginLeft: '6px',
            marginRight: '6px',
        },
    },
    grid: {
        marginTop: theme.spacing(1, 0, 0, 0)
    },
    tab: {
        paddingLeft: '50px',
        color: 'rgb(15, 190, 215)'
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    textCol: {
        alignSelf: 'center',
    },
    imgIcon: {
        padding: theme.spacing(1),
        height: '70px'
    },
    FormControl: {
        paddingBottom: theme.spacing(7),
        width: '100%'
    },
    CheckBox: {
        paddingBottom: theme.spacing(2),
        // paddingRight: theme.spacing(3),
        width: '100%',
        '& .MuiFormControlLabel-root': {
            marginRight: '2px'
        },
    },
    input: {
        fontSize: '8px'
    },
    title: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    changePaychannelText: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        display: 'flex',
        fontSize: '20px',
        color: '#00bfd8',
        alignItems: 'center'
    },
    consentText1: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '22px',
    },
    consentText2: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '22px',
        color: '#00bfd8'
    },
    labelStyle: {
        fontSize: '22px',
        span: { fontSize: '22px' }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        color: '#000000'
    },
    resize: {
        fontSize: '30px'
    },
    link: {
        fontFamily: 'DBHeaventRoundedMedv32'
    },
}));
exports.default = style;
