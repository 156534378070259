"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HTTPMethodEnum_1 = require("common/Manager/Enumeration/HTTPMethodEnum");
const ServerConfig_1 = require("common/Config/ServerConfig");
class LabelTranslationAPIRequest {
    constructor(jwt) {
        this.method = HTTPMethodEnum_1.HTTPMethodEnum.GET;
        this.jwt = jwt;
        this.url = `${ServerConfig_1.serverConfig.WEB_LABEL_ENDPOINT}/api/sabuypay-web-configs`;
    }
    makeQuery() {
        return {};
    }
    makeHeader() {
        return {
            'Authorization': `Bearer ${this.jwt}`
        };
    }
    makeBody() {
    }
}
exports.default = LabelTranslationAPIRequest;
