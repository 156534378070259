"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const APIConfig_1 = require("common/Config/APIConfig");
class ImageManager {
    constructor() {
        this.images = {
            common: {
                flag: {
                    TH: (APIConfig_1.getModuleAssetsUrl('common') + '/flag/TH.svg'),
                    EN: (APIConfig_1.getModuleAssetsUrl('common') + '/flag/EN.svg'),
                },
                creditCardIcons: {
                    visa: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/visa.png'),
                    masterCard: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/mastercard.png'),
                    amax: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/credit-card-icons/amax.png'),
                },
                bankIcons: {
                    kbank: {
                        icon: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-icons/kbank.png'),
                        appIcon: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-app-icons/kbank.png'),
                        appIconDisabed: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-app-icons/kbank-disabled.png'),
                        internetBankingIcon: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-instructions/internet-banking-kbank.png'),
                        atmIcon: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-instructions/atm-kbank.png'),
                        counterIcon: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/bank-instructions/counter-service-kbank.png'),
                    }
                },
                emailWhite: (APIConfig_1.getModuleAssetsUrl('common') + '/payment-methods/email-white.png'),
                crown: (APIConfig_1.getModuleAssetsUrl('common') + '/crown.png'),
                header: {
                    LogoImage: (APIConfig_1.getModuleAssetsUrl('template') + '/ttc-logo.svg'),
                    DownloadApp: (APIConfig_1.getModuleAssetsUrl('template') + '/download.svg'),
                    LineIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/line-color.svg'),
                    FBIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/facebook-color.svg'),
                    IGIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/instagram-color.svg'),
                    Avatar: (APIConfig_1.getModuleAssetsUrl('template') + '/Bright.jpg'),
                    ThaiFlag: (APIConfig_1.getModuleAssetsUrl('template') + '/thailand-flag.svg'),
                    currencyIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/currency.svg'),
                },
                toggleMenu: {
                    lineIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/line.svg'),
                    messengerIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/messenger.svg'),
                    appstoreImage: (APIConfig_1.getModuleAssetsUrl('template') + '/appstore-color.svg'),
                    playstoreImage: (APIConfig_1.getModuleAssetsUrl('template') + '/googleplay-color.svg'),
                },
                footerImage: (APIConfig_1.getModuleAssetsUrl('common') + '/footer-image.png'),
                footer: {
                    bgfooter: (APIConfig_1.getModuleAssetsUrl('template') + '/kanok.svg'),
                    newsletter: (APIConfig_1.getModuleAssetsUrl('template') + '/3737.png'),
                    appstoreImage: (APIConfig_1.getModuleAssetsUrl('template') + '/appstore.svg'),
                    playstoreImage: (APIConfig_1.getModuleAssetsUrl('template') + '/googleplay.svg'),
                    socialIcon: {
                        facebookIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/facebook.svg'),
                        twitterIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/twitter.svg'),
                        youtubeIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/youtube.svg'),
                        instagramIcon: (APIConfig_1.getModuleAssetsUrl('template') + '/instagram.svg'),
                    }
                },
                lineShared: (APIConfig_1.getModuleAssetsUrl('template') + '/line-shared-color.svg'),
                pdf: (APIConfig_1.getModuleAssetsUrl('template') + '/pdf.svg'),
                printer: (APIConfig_1.getModuleAssetsUrl('template') + '/printer.svg'),
                email: (APIConfig_1.getModuleAssetsUrl('template') + '/email.svg'),
                shared: (APIConfig_1.getModuleAssetsUrl('template') + '/share.svg'),
                map: (APIConfig_1.getModuleAssetsUrl('template') + '/map-point.svg'),
            },
            sabuypay: {
                icon: {
                    logo: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/icons/SabuyPay-logo.svg'),
                },
                security: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/security.svg'),
                logoheader: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/bg-sabuypay.jpg'),
                logoheader_mobile: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/bg-sabuypay-mobile.jpg'),
                logofooter: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/gdsv.png'),
                pcidss: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/pci-dss.png'),
                ssl: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/ssl.png'),
                successimg: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/success-img.jpg'),
                deniedimg: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/denied-img.jpg'),
                printer: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/printer.svg'),
                reference: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/reference.svg'),
                scancode: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/scan-code.svg'),
                hourglass: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/hourglass.png'),
                jcb_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/creditcard/jcb_icon.svg'),
                mastercard_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/creditcard/mastercard_icon.svg'),
                visa_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/creditcard/visa_icon.svg'),
                union_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/creditcard/union.png'),
                amex_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/creditcard/amex.svg'),
                thaiqr_logo: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/thaiqr/thaiqr_logo.png'),
                thai_qr_image: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/thaiqr/thai-qr-image.png'),
                promptpay_logo: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/thaiqr/promptpay-logo.png'),
                payment_method: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/payment_method.png'),
                flag: {
                    TH: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/flag/th.gif'),
                    EN: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/flag/us.gif'),
                },
                register: {
                    flag: {
                        TH: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/flag_th.png'),
                        EN: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/flag_en.png'),
                    },
                    register_icon: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/register_icon.png'),
                    person_outline_24dp: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/person_outline_24dp.png'),
                    bg_footer: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/bg_footer.png'),
                    payment_solution: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/payment_solution5.png'),
                    payment_solution2: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/payment_solution2.jpeg'),
                    bg_formregister2: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/bg_formregister2.png'),
                    letter: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/letter.png'),
                    lock: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/lock.png'),
                    letter_m: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/letter_m.png'),
                    lock_m: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/lock_m.png'),
                    icon_submit: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/icon_submit.png'),
                    sabuypay_log_horizontal: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/sabuypay-log-horizontal.png'),
                    sabuypay_log_horizontal_mobile: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/sabuypay-log-horizontal-mobile.png'),
                    border_right: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/border_right.png'),
                    expand_more: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/expand_more_18dp.png'),
                    pcidss: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/pcidss.png'),
                    bars: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/bars.png'),
                    outline_close_black_24dp: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/outline_close_black_24dp.png'),
                    app_registration_black_18dp: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/app_registration_black_18dp.svg'),
                    person_black_18dp: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/person_black_18dp.svg'),
                    call_black_18dp: (APIConfig_1.getModuleAssetsUrl('sabuypay') + '/register/call_black_18dp.svg')
                }
            },
        };
    }
}
ImageManager.default = new ImageManager();
exports.default = ImageManager;
