"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RegularExpression {
    constructor() {
        this.regEx = {
            name: /^[a-zA-Z ]+$/,
            fullName: /^[a-zA-Z ]+$/,
            fullNameThEn: /^[a-zA-Zก-ฮะ-์- ]+$/,
            firstLastName: /^[a-zA-Z .]+$/,
            usernameIncludeThai: /^[a-zA-Zก-ฮะ-์]+$/,
            passportNumber: /^[a-zA-Z0-9]+$/,
            citizenNumber: /^[0-9]\d{12}$/,
            //email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            email: /^(([^<>()\[\]\\.,;!@#$%^&*-/:\s@"]+(\.[^<>()\[\]\\.,;!@#$%^&*-/:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            //emailKeyUp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~@-]+$/,
            emailKeyUp: /^[a-zA-Z0-9._@]+$/,
            phoneNumber: /^[0-9]+$/,
            thaiPhoneNumber: /^[0-9]{10}$/,
            creditCard: /^[0-9]\d{15}$/,
            cvv: /^[0-9]{3,4}$/,
            charactersAndNumbers: /^[a-zA-Z0-9ก-ฮะ-์- ]+$/,
            nationality: /^[a-zA-Z0-9- ]+$/,
            any: /^[\s\S]/,
            numbers: /^[0-9]+$/,
            amount: /^[0-9.]+$/,
            flightBooking: /^[0-9]{10}$/,
            charactersENAndNumbers: /^[a-zA-Z0-9]+$/,
            lowercase: /[a-z]/g,
            uppercase: /[A-Z]/g,
            numberic: /[0-9]/g,
            specialchar: /[!@#$%^&*]/g,
            passLowercase: /^(?=.*[a-z]).+$/,
            passUppercase: /^(?=.*[A-Z]).+$/,
            passNumberic: /^(?=.*[0-9]).+$/,
            passSpecialchar: /^(?=.*[_\W]).+$/,
            passwordCaseThai: /[ก-๙]+$/,
        };
    }
    validate(regex, text, allowEmpty) {
        const trimmedText = (text || '');
        if (trimmedText.length == 0 && allowEmpty)
            return true;
        return regex.test(trimmedText);
    }
    NameValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.name, text, allowEmpty);
    }
    fullNameValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.fullName, text, allowEmpty);
    }
    fullNameThEnValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.fullNameThEn, text, allowEmpty);
    }
    firstLastNameValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.firstLastName, text, allowEmpty);
    }
    UserNameValidateIncludeThai(text = '', allowEmpty = true) {
        return this.validate(this.regEx.usernameIncludeThai, text, allowEmpty);
    }
    PassportNumberValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passportNumber, text, allowEmpty);
    }
    identificationNumberValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.citizenNumber, text, allowEmpty);
    }
    emailValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.email, text, allowEmpty);
    }
    emailKeyUpValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.emailKeyUp, text, allowEmpty);
    }
    phoneNumberValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.phoneNumber, text, allowEmpty);
    }
    thaiPhoneNumberValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.thaiPhoneNumber, text, allowEmpty);
    }
    amountValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.amount, text, allowEmpty);
    }
    numberValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.numbers, text, allowEmpty);
    }
    lowercaseValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.lowercase, text, allowEmpty);
    }
    uppercaseValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.uppercase, text, allowEmpty);
    }
    numbericValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.numberic, text, allowEmpty);
    }
    specialcharValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.specialchar, text, allowEmpty);
    }
    /* */
    passLowercaseValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passLowercase, text, allowEmpty);
    }
    passUppercaseValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passUppercase, text, allowEmpty);
    }
    passNumbericValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passNumberic, text, allowEmpty);
    }
    passSpecialcharValidate(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passSpecialchar, text, allowEmpty);
    }
    passwordCaseThai(text = '', allowEmpty = true) {
        return this.validate(this.regEx.passwordCaseThai, text, allowEmpty);
    }
}
RegularExpression.default = new RegularExpression();
exports.default = RegularExpression;
