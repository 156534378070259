"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    Address: {
        lineHeight: theme.spacing(5),
        padding: theme.spacing(2),
        color: 'rgb(15, 190, 215)'
    },
    section2: {
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: '30px 60px',
    },
    logo: {
        width: '100px',
        marginRight: '10px'
    },
    logo_title: {
        width: '100px',
        marginRight: '10px'
    },
    textSecurity: {
        fontSize: '20px',
        fontFamily: 'DBHeaventRoundedMedv32',
        textAlign: "right"
    },
    detailSecurity: {
        fontSize: '18px',
        textAlign: "right"
    },
    top_price: {
        paddingRight: '16px',
        fontSize: '16px',
        height: '50%',
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        width: "220px"
    },
    bottom_price: {
        paddingRight: '30px',
        fontSize: '23px',
        // color: '#00bfd8',
        backgroundColor: '#f2f3fa',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        fontFamily: 'DBHeaventRoundedMedv32',
        height: '50%',
        width: "220px",
        textAlign: "right"
    },
    box: {
        //  backgroundColor: theme.palette.background.paper,    
        //  padding: '20px 30px',   
        padding: '20px',
        // boxShadow: '1px 1px 3px 0px #e0e0e0b3',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        marginTop: '20px'
        // marginTop: '6px', 
        // marginBottom: '12px', 
        // marginLeft: '6px',    
        // marginRight: '6px',     
    },
    box_barcode: {
        display: "flex",
        justifyContent: "flex-end"
        // background:"#cccccc"
    },
    barcode_container: {
        svg: {
            width: '300px',
        }
    },
    barcodeText: {
        // paddingTop:'-5px'
        // lineHeight: '0.5'
        padding: 0,
        margin: 0,
        textAlign: 'center'
    },
    box_paymentmethod: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    img_counterpay: {
        marginTop: '18px',
        height: '40px'
    },
    img_counterservice: {
        marginTop: '20px',
        height: '30px',
    },
    box_ref: {},
    subtitle: {
        fontSize: '16px',
        color: '#BBBBBB',
        marginBottom: '-8px',
        width: '130px',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between'
    },
    subtitleDetail: {
        fontSize: '16px',
        marginBottom: '-8px',
        textAlign: 'left',
    },
    box_price: {
        display: "flex",
        justifyContent: "flex-end"
    },
    colon_space: {
        fontSize: '16px',
        color: '#BBBBBB',
        marginRight: '15px'
    },
    grid: {
        marginTop: theme.spacing(1, 0, 0, 0)
    },
    papertwo: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {},
    tab: {
        paddingLeft: '50px',
        color: 'rgb(15, 190, 215)'
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    imgIcon: {
        padding: theme.spacing(1),
        height: '70px'
    },
    FormControl: {
        paddingBottom: theme.spacing(7),
        width: '100%'
    },
    ref: {
        fontSize: '16px',
        // paddingTop:'9px',
        paddingBottom: '8px',
        // paddingRight:'10px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    scan_barcode_text: {
        fontSize: '16px',
    },
    box_scan_barcode: {
        marginTop: "15px",
        //  display:"flex",
        //  justifyContent:"center" ,
        //  background:"#dddddd"     ,
        marginLeft: 0,
        marginRight: 0,
        padding: 0
    },
    label_ref: {
        padding: '7px 10px',
        fontSize: '23px',
        fontFamily: 'DBHeaventRoundedMedv32',
        backgroundColor: '#f2f3fa'
    },
    box_howtopayment: {
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex'
    },
    text_howtopayment: {
        lineHeight: 1.0,
        color: '#00bfd8',
        fontSize: '20px'
    },
    text_howtopayment_detail: {
        lineHeight: 0.9,
        fontSize: '16px',
        width: '130px',
        "@media (max-width: 960px)": {
            width: '100%'
        },
    },
    title: {
        color: '#364469',
        fontSize: '16px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    title_billpayment: {
        color: '#364469',
        fontSize: '16px',
        fontFamily: 'DBHeaventRoundedMedv32',
        // paddingTop: '5px',
        paddingBottom: '13px',
    },
    servicecharge: {
        color: '#000000',
        fontSize: '16px',
        fontFamily: 'DBHeaventRoundedMedv32',
        lineHeight: 1,
    },
    paymentmethod_detail: {
        color: '#000000',
        marginTop: "-8px",
        fontSize: '14px',
        lineHeight: 1,
        whiteSpace: 'pre-line'
    },
    paymentmethod_ref1: {
        color: '#00bfd8'
    },
    servicecharge_detail: {
        color: '#000000',
        fontSize: '14px',
        lineHeight: 0.8,
    },
    changePaychannelText: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '20px',
        color: '#00bfd8',
        display: 'flex',
        alignItems: 'center'
    },
    merchant_name: {
        fontSize: '20px',
        color: '#00bfd8',
    },
    labelStyle: {
        fontSize: '22px',
        span: { fontSize: '22px' }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        color: '#000000'
    },
    resize: {
        fontSize: '30px'
    },
    link: {
        cursor: 'pointer'
    },
    barcode: {
        width: '200px'
    },
    provider_list: {
        fontSize: '14px',
        paddingBottom: '10px',
    },
    row_provider: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '10px'
    },
    row_img: {
        display: 'flex',
        alignItems: "center",
        marginRight: '14px'
    },
    flex_item_center: {
        display: 'flex',
        alignItems: "center"
    },
    header_detail: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: '6px'
    }
}));
exports.default = style;
