"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        fontSize: '20px',
    },
    form_provider: {
        fontSize: '20px',
    },
    textField: {
        fontSize: '20px',
    },
    title: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    title_billpayment: {
        color: '#000000',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    divider: {
        marginTop: '12px',
        marginBottom: '14px',
        backgroundColor: "#eeeeee"
    },
    divider_content: {
        backgroundColor: "#eeeeee",
        marginTop: '22px',
        marginBottom: '22px',
    },
    select: {
        fontSize: "20px",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px",
        marginTop: '6px',
        marginRight: '6px'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: 'visible !important',
    },
    tab_container: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    form: {
        paddingLeft: "30px",
    },
    creditcard: {
        border: '1px solid #1b1b1b',
        padding: '6px',
        borderRadius: '5px',
    },
    creditcard_union: {
        border: '1px solid #1b1b1b',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '11px',
        paddingBottom: '11px',
        borderRadius: '5px',
    },
    description_m: {
        marginLeft: '6px'
    },
    description_1: {
        color: '#000000',
        marginTop: "4px",
        marginBottom: "12px",
        fontSize: '20px',
        width: "100%",
        lineHeight: 0.9,
        whiteSpace: 'pre-line',
    },
    description_2: {
        color: '#000000',
        marginTop: "20x",
        marginBottom: "20px",
        fontSize: '20px',
        lineHeight: 1.1,
        width: "100%",
        whiteSpace: 'pre-line',
    },
    textconsent: {
        lineHeight: 0.9,
        fontSize: '20px',
        marginBottom: '12px',
        '@media (max-width:960px)': {
            marginBottom: '22px',
        }
    },
    box_credit: {
        marginTop: "20px",
        display: 'flex',
        width: '100%'
    },
    btn_billpayment: {
        paddingLeft: '6px',
        paddingRight: '6px',
    },
    box_submit: {
        width: '250px',
        paddingTop: '20px',
        paddingBottom: '15px',
    },
    link: {
        cursor: 'pointer'
    },
    link_button: {
    // -webkit-appearance: button;
    // -moz-appearance: button;
    }, a: {
        color: '#00bfd8',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    tabLabel: {
        fontSize: '18px'
    },
    formControl: {
        "& .MuiInputLabel-shrink, & fieldset": {
            fontSize: "22px"
        },
    },
    tabPanelRoot: {
        padding: 0
    },
    inputlabel: {
        fontSize: '20px',
    },
    selectChannel: {
        "& .MuiInputLabel-shrink, & fieldset": {
            fontSize: "24px",
        },
    }
}));
exports.default = style;
