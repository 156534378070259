"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ColorManager {
    constructor() {
        this.primaryColor = '#440099';
        this.secondaryColor = '#3f256b';
        this.thirdColor = '#230550';
        this.fourthColor = '#888aaa';
        this.fifthColor = '#694a8d';
        this.greyColor = '#f3f2fa';
        this.greyPaleColor = '#c1c1c1';
        this.redColor = '#ed1c24';
        this.orangeColor = '#e85e34';
        this.orange2Color = '#f38141';
        this.yellowColor = '#ffc412';
        this.greenColor = '#009780';
        this.subLabelColor = '#949494';
        this.redCancel = '#A22C3A';
        this.greenApprove = '#35a68d';
        this.white = '#FFFFFF';
        this.separator = '#E1E1E1';
        this.grey = '#C1C2C3';
        this.lightGrey = 'rgba(0,0,0,0.15)';
        this.white50a = 'rgba(255,255,255,0.60)';
        this.background = '#F5F5F5';
        this.appBackground = '#D9DEEA';
        this.black = '#0b0b0b';
        this.text = '#000000';
        this.redAlert = '#D02D2B';
        this.brown = '#998675';
        this.darkbrown = '#53443B';
        this.elmGreen = '#34A68C';
        this.appBackgroundDarken = '#ACAEC1';
        this.sunflower = '#f1c40f';
        this.red = '#EE0833';
        this.appRed = '#EE0833';
        this.appGreen = '#009780';
        this.appDarkGrey = '#BBBCCC';
        this.emerald = '#1F91A2';
        this.line = '#00c300';
        this.bankTheme = {
            kbank: '#138f2d',
            uob: '#0b3979',
            tmb: '#1279be',
            scb: '#4e2e7f',
            ktb: '#1ba5e1',
            bbl: '#012086',
            bay: '#5e4c4c'
        };
        this.counterTheme = {
            bigC: '#b3d236',
            sevenEleven: '#127757',
            counterService: '#0b3f92'
        };
        this.hotelTheme = {
            HeaderList: '#f6e5e7',
        };
        this.appOrange = '#F97733';
        this.pendingOrange = '#f39c12';
        this.transparent = '#00000000';
    }
    get appColor() { return this.primaryColor; }
    get appColor2() { return this.secondaryColor; }
}
ColorManager.default = new ColorManager();
exports.default = ColorManager;
