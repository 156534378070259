"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getModuleAssetsUrl = void 0;
const history_1 = require("common/history");
const PROD_API_GATEWAY_DOMAIN = "";
//const DEV_API_GATEWAY_DOMAIN = "https://dev-api.sabuypay.com";
// const PROD_API_GATEWAY_DOMAIN = "http://localhost:5000";
const DEV_API_GATEWAY_DOMAIN = "http://localhost:5000";
const DEV_DOMAIN = "http://localhost:3000";
const PROD_TTC_API_GATEWAY_DOMAIN = "https://www.thaitravelcenter.com";
const HOSTNAME = "travizgo.com";
const PROD = {
    app: `${PROD_API_GATEWAY_DOMAIN}/app/api`,
    web: `https://dev-api.sabuypay.com` + history_1.BASENAME,
    assets: `https://assets.sabuypay.dev/images`,
    flight: {
        apiStandardSocket: `https://api-flight.${HOSTNAME}`,
        apiStandard: `${PROD_API_GATEWAY_DOMAIN}/flight/api`,
        creditCardFormPostUrl: `https://payment.${HOSTNAME}/Api/AllPayment/form_checkout3`,
        qrCreditAPI: `https://www.travizgo.com/api/payment/gateway/gb/qrcredit/`,
        invoice: `https://payment.${HOSTNAME}/Api/RenderFile/CreateReceipt`,
    },
    hotel: {
        hotel_proxy: `${PROD_API_GATEWAY_DOMAIN}/hotel/api/`,
        hotel_Payment: `https://dev-vm.travizgo.site/Travizgo/AllPayment/form_checkout3`,
        hotel_standard: `https://dev-hotel-backoffice.travizgo.site/`,
        hotel_CMS: `${PROD_API_GATEWAY_DOMAIN}/cms/hotel/content/`,
        autoComplete: `${PROD_API_GATEWAY_DOMAIN}/hotel-standard/api/v1/hotel/autocomplete`,
        search: `${PROD_API_GATEWAY_DOMAIN}/hotel-standard/api/search`,
        roomlist: `${PROD_API_GATEWAY_DOMAIN}/hotel-standard/api/v1/hotel/rooms/list`,
        label: `https://dev-label-translation.ttcglobal.network/fetch`
    },
    package: {
        cms: `${PROD_API_GATEWAY_DOMAIN}/cms/sightseeing`,
        creditCardFormPostUrl: `https://payment.${HOSTNAME}/Api/AllPayment/form_checkout3`,
        langUrl: `${PROD_API_GATEWAY_DOMAIN}/sightseeing/backoffice/api`,
        apiStandard: `${PROD_API_GATEWAY_DOMAIN}/sightseeing/backoffice/api`,
    },
    collective: {
        autoComplete: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/search.php`,
        autoCompleteDev: `http://localhost:3000/collective/autocomplete`,
        tourSEO: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/seo.php`,
        tourCategory: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/category.php`,
        tourList: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/list.php`,
        tourListDev: `http://localhost:3000/collective/tourlist`,
        tourListFilter: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/filter.php`,
        tourListRelated: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/related.php`,
        tourDetail: `${PROD_TTC_API_GATEWAY_DOMAIN}/tour-api/module/detail.php`,
        tourDetailDev: `http://localhost:3000/collective/tourdetail`,
    },
    landingPage: `${PROD_API_GATEWAY_DOMAIN}/cms/landing`,
    assetsModules: {
        flight: "flight",
        hotel: "hotel",
        landingPage: "landing_page",
        payment: "payment",
        common: "common",
        package: "package",
        account: "account",
        collectiveTour: "collective_tour",
        sabuypay: "sabuypay"
    },
};
const DEV = {
    app: `${DEV_API_GATEWAY_DOMAIN}/app/api`,
    web: `https://dev-for-cms.travizgo.site` + history_1.BASENAME,
    // assets: ` https://assets.sabuypay.com/development`,
    assets: `https://assets.sabuypay.dev/images`,
    //assets: `http://localhost`,
    apiservice: `${DEV_API_GATEWAY_DOMAIN}`,
    hotel: {
        hotel_proxy: `${DEV_API_GATEWAY_DOMAIN}/hotel/api/`,
        hotel_Payment: `https://payment.${HOSTNAME}/Api/AllPayment/form_checkout3`,
        hotel_standard: `https://hotel-backoffice.travizgo.com/`,
        hotel_CMS: `${DEV_API_GATEWAY_DOMAIN}/cms/hotel/content/`,
        autoComplete: `${DEV_API_GATEWAY_DOMAIN}/hotel-standard/api/v1/hotel/autocomplete`,
        search: `${DEV_API_GATEWAY_DOMAIN}/hotel-standard/api/search`,
        roomlist: `${DEV_API_GATEWAY_DOMAIN}/hotel-standard/api/v1/hotel/rooms/list`,
        hotelListSocket: 'https://socket-hotel-service.travizgo.dev',
        popular_city: 'https://demo1659663.mockable.io/popular',
        label: `https://dev-label-translation.ttcglobal.network/fetch`
    },
    landingPage: `${DEV_API_GATEWAY_DOMAIN}/cms/landing`,
    assetsModules: {
        flight: "flight",
        hotel: "hotel",
        landingPage: "landing_page",
        payment: "payment",
        common: "common",
        package: "package",
        account: "common_dev",
        collectiveTour: "collective_tour",
        template: "template",
        sabuypay: "sabuypay"
    },
};
/** TODO-production: Change this */
const APIConfig = DEV;
exports.default = APIConfig;
exports.getModuleAssetsUrl = (module) => {
    const API = DEV;
    return `${API.assets}`;
};
