"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative',
    },
    instruction: {
        fontSize: '20px',
        marginBottom: '18px'
    },
    instruction2: {
        fontFamily: "DBHeaventRoundedMedv32",
    },
    paper2: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px"
    },
    img: {
        verticalAlign: 'middle',
        marginBottom: '7px'
    }
}));
exports.default = style;
