"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        padding: theme.spacing(2, 0),
        boxShadow: '0px 0.5px 0px 1px rgb(220 220 220)',
        backgroundColor: 'white'
    },
    noPadding: {
        padding: theme.spacing(2, 3, 2, 3),
    },
    logo: {
        width: '150px',
    },
    icon_header: {
        height: '32px',
    },
    border_right: {
        borderRight: '1px solid #e0e0e0',
        paddingRight: '24px;'
    },
    box_img: {
        paddingRight: '24px;'
    },
    box_flag: {
        paddingLeft: '8px;'
    },
    Flag: {
        borderRadius: 4,
        height: 23
    },
    FlagMenu: {
        borderRadius: 4,
        height: 15
    },
    currencySelect: {
        color: '#000000',
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '20px',
        paddingLeft: '15px;',
    },
    currencyPaper: {
        top: '53px !important',
        "& .MuiMenu-list": {
            padding: 0
        },
        "& .MuiMenuItem-root": {
            fontSize: 17,
            margin: "5px 0"
        }
    },
    "@media (max-width: 414px) ": {
        logo: {
            width: '120px',
        },
        icon_header: {
            height: '24px',
        },
        box_img: {
            paddingRight: '12px;'
        },
        border_right: {
            borderRight: '1px solid #e0e0e0',
            paddingRight: '12px',
        },
        box_flag: {
            marginLeft: '-12px'
        },
        Flag: {
            borderRadius: 4,
            height: 20
        },
        FlagMenu: {
            borderRadius: 4,
            height: 15
        },
    },
    Container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '1110px',
    },
}));
exports.default = useStyles;
