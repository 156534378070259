"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                color: 'white',
            },
        },
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    section2: {
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: '14px',
    },
    paper2: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px"
    },
    "@media (max-width: 960px)": {
        paper: {
            padding: '25px 14px',
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '6px',
        },
    },
    "@media (min-width:960px)": {
        paper: {
            padding: '25px 30px',
            boxShadow: '1px 1px 3px 0px #e0e0e0b3',
            marginTop: '12px',
            marginBottom: '12px',
            marginRight: '6px',
        },
    },
    box: {
        marginBottom: '1.3em'
    },
    tab: {
        paddingLeft: '50px',
        color: 'rgb(15, 190, 215)'
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    textField: {
        // width: 200,
        fontSize: '30px'
    },
    title: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    labelStyle: {
        fontSize: '22px',
        span: { fontSize: '22px' }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    link: {
        cursor: 'pointer'
    },
    inputlabel: {
        fontSize: '20px',
    },
    content: {
        fontSize: '17px',
    }
}));
exports.default = style;
