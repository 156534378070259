"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => ({
    input: {
        background: "#FFFFFF",
        border: "1px solid #E7E7E7",
        borderRadius: "10px"
    },
    box_img: {
        position: "relative",
        width: "490px",
        height: "720px",
        padding: "5px",
    },
    top_left: {
        position: "absolute",
        top: "69px",
        left: "63px",
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
    },
    subject: {
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,
        fontWeight: 400,
        fontSize: "36px",
        marginBottom: "50px"
    },
    content_main: {
        display: "flex",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.04)",
        borderRadius: "20px",
        width: "1108px",
        height: "730px",
    },
    regis_detail1: {
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: " 18px",
        textAlign: "center",
        color: "#3E425B"
    },
    regis_detail2: {
        fontStyle: "normal",
        fontFamily: `'NotoSansRegular','NotoSansRegularThai'`,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: " 18px",
        textAlign: "center",
        color: "#0FBED7"
    },
    a_link: {
        color: "#0FBED7",
        textDecoration: "none",
        cursor: "pointer"
    },
    container_regis: {
    //  width: "100%",
    //  height: "100%",
    //  padding: "15px",
    // marginTop: "80px",
    },
    regis_detail3: {
        fontFamily: 'Noto Sans',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "18px",
        color: "#3E425B"
    },
    box_content: {
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
    },
    input_box: {
        marginBottom: "30px",
    },
    input_text: {
        marginBottom: "30px",
    },
    img_payment_solution: {
        width: "100%",
        height: "720px",
    },
    icon_submit: {
        marginLeft: "20px",
        verticalAlign: "middle"
    },
    label2: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "18px",
        color: "#3E425B"
    },
    label3: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "14px",
        color: "#3E425B"
    },
    check_list: {
        border: '1px solid #E7E7E7',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
        padding: '50px 30px',
        background: '#ffffff',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '5px',
        marginBottom: "25px",
    },
    list: {
        display: 'flex',
        padding: '15px 20px',
        border: '1px solid #e7e7e7',
        borderRadius: '5px',
        fontWeight: 600,
        background: '#ffffff',
    },
    "@media only screen and (max-width: 992px) ": {
        container_regis: {
            width: "100%",
            height: "100%",
            padding: "15px",
            marginTop: "80px",
        },
        box_content: {
            marginBottom: "2px",
        },
        box_img: {
            display: "None",
        },
        content_main: {
            background: "#FFFFFF",
            display: 'block',
            width: "100%",
            height: "100%",
            padding: "15px"
        },
        subject: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "24px",
            marginTop: "23px",
            // marginTop: "9.86%",
            marginBottom: "38px"
        },
        regis_detail1: {
            fontSize: "14px",
            lineHeight: "14px",
        },
        regis_detail2: {
            fontSize: "14px",
            lineHeight: "14px",
        },
        regis_detail3: {
            fontSize: "14px",
            lineHeight: "14px",
        },
        input_box: {
            marginBottom: "25px",
        },
        input_text: {
            paddingBottom: "28px",
        },
        marginBottom: "25px",
    },
    "@media only screen and (min-width: 992px) ": {
        box_content: {
            marginBottom: "2px",
            marginTop: "4.93%",
        },
        container_regis: {
            background: "#F3F2FA",
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '107px',
            marginTop: "90px",
            paddingBottom: '107px',
            width: '100%',
            height: '934px',
            //height: '934px',           
            paddingLeft: '5%',
            paddingRight: '5%',
        },
        content_main: {
            display: "flex",
            alignItems: "flex-start",
            background: "#FFFFFF",
            boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.04)",
            borderRadius: "20px",
            width: "100%",
            height: "730px",
        },
    },
    "@media only screen and (min-width: 1200px) ": {
        container_regis: {
            background: "#F3F2FA",
            marginLeft: 'auto',
            marginRight: 'auto',
            // paddingTop: '10.9%',
            // paddingBottom: '10.9%',
            width: '100%',
            height: '934px',
            //height: '934px',           
            paddingLeft: '11.5%',
            paddingRight: '11.5%',
        },
        content_main: {
            display: "flex",
            alignItems: "flex-start",
            background: "#FFFFFF",
            boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.04)",
            borderRadius: "20px",
            width: "100%",
            height: "730px",
        },
    },
    icon: {
        padding: "10px",
        //  color: "white",
        minWidth: "50px",
        textAlign: "center",
        color: "#BFBFBF",
        borderTop: '1px solid #E7E7E7',
        borderBottom: '1px solid #E7E7E7',
        borderLeft: '1px solid #E7E7E7',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        background: "#FFFFFF",
        borderRight: "none",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
    },
}));
exports.default = useStyles;
