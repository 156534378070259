"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                color: 'white',
            },
        },
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    Address: {
        lineHeight: theme.spacing(5),
        padding: theme.spacing(2),
        color: 'rgb(15, 190, 215)'
    },
    section2: {
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: '14px',
    },
    paper2: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px"
    },
    "@media (max-width: 960px)": {
        paper: {
            padding: '25px 14px',
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '6px',
        },
    },
    "@media (min-width:960px)": {
        paper: {
            padding: '25px 30px',
            boxShadow: '1px 1px 3px 0px #e0e0e0b3',
            marginTop: '6px',
            marginBottom: '12px',
            marginRight: '6px',
        },
    },
    form: {
    // paddingLeft:"30px",
    // paddingRight:"30px",
    },
    box_left: {
        "@media (min-width:960px)": {
            marginRight: '1em',
        },
        marginBottom: '1.3em',
    },
    box: {
        marginBottom: '1.3em'
    },
    uploadBox: {
        position: 'relative',
        marginBottom: '20px',
    },
    uploadBtnWrapper: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '100%',
        height: '80px',
        border: '3px dashed #cccccc',
        cursor: 'pointer',
        textAlign: 'center',
    },
    file: {
        position: 'absolute',
        left: 0,
        top: 0,
        cursor: 'pointer',
        width: '100%',
        height: '80px',
        opacity: 0
    },
    textupload: {
        color: '#cccccc',
        fontSize: '20px'
    },
    tab: {
        paddingLeft: '50px',
        color: 'rgb(15, 190, 215)'
    },
    Divider: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    imgPreview: {
        width: '250px',
        height: 'auto',
        textAlign: "center"
    },
    ref: {
        fontSize: '20px',
        paddingTop: '9px',
        paddingBottom: '13px',
        paddingRight: '10px',
        fontFamily: 'DBHeaventRoundedMedv32',
        "@media (max-width: 960px)": {
            textAlign: "center"
        },
    },
    boxImgPreview: {
        margin: '10px 10px',
        textAlign: "center",
    },
    uploadError: {
        color: '#d32f2f',
        marginTop: '4px',
        fontSize: "18px",
        textAlign: 'center'
    },
    formSelect: {
        "& .MuiInputLabel-shrink, & fieldset": {
            fontSize: "25px"
        },
        marginBottom: '20px'
    },
    label_ref: {
        padding: '7px 10px',
        fontSize: '34px',
        fontFamily: 'DBHeaventRoundedMedv32',
        backgroundColor: '#f2f3fa'
    },
    textField: {
        // width: 200,
        fontSize: '30px'
    },
    title: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    title_billpayment: {
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
        // paddingTop: '15px',
        paddingBottom: '15px',
        "@media (max-width: 410px)": {
            lineHeight: 0.9,
            paddingBottom: '20px',
        },
    },
    servicecharge: {
        color: '#000000',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
        lineHeight: 1,
    },
    box_paymentmethod: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paymentmethod_detail: {
        color: '#000000',
        marginTop: "-8px",
        fontSize: '20px',
        lineHeight: 1.1,
        whiteSpace: 'pre-line',
    },
    img_counterpay: {
        marginTop: '20px',
        width: '65px',
        height: '65px',
    },
    img_counterservice: {
        marginTop: '25px',
        // width:'65px',
        height: '50px',
        "@media (max-width: 960px)": {
            height: '35px',
            marginLeft: '8px',
            marginTop: '17px',
        },
    },
    paymentmethod_ref1: {
        color: '#00bfd8'
    },
    servicecharge_detail: {
        color: '#000000',
        fontSize: '20px',
        lineHeight: 0.8,
    },
    changePaychannelText: {
        // fontFamily: 'DBHeaventRoundedMedv32',
        fontSize: '20px',
        color: '#00bfd8',
        display: 'flex',
        alignItems: 'center'
    },
    merchant_name: {
        fontSize: '20px',
        color: '#00bfd8',
    },
    labelStyle: {
        fontSize: '22px',
        span: { fontSize: '22px' }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    resize: {
        fontSize: '30px'
    },
    link: {
        cursor: 'pointer'
    },
    barcode: {
        width: '200px'
    },
    provider_list: {
        fontSize: '19px',
        paddingBottom: '10px',
        "@media (max-width: 960px)": {
            fontSize: '18px',
        },
    },
    row_provider: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '10px'
    },
    inputlabel: {
        fontSize: '20px',
    }
}));
exports.default = style;
