"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BtnSubmitRegister = exports.BtnLogin = exports.BtnRegister = exports.BtnSubmit = void 0;
require("react-credit-cards/es/styles-compiled.css");
const styled_components_1 = __importStar(require("styled-components"));
exports.BtnSubmit = styled_components_1.default('button') `

  border: none;
  box-shadow: none;
 
  padding: 12px 16px;
  min-width: 64px;
  font-family: DBHeaventRoundedmedv32; 
  font-size:23px;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  width: 100%;
  ${props => props.disabled ?
    styled_components_1.css `
      color: rgba(0, 0, 0, 0.26);     
      background-color: rgba(0, 0, 0, 0.12);
    ` : styled_components_1.css `
    color: #ffffff;   
    cursor:pointer;
    background-image: linear-gradient(45deg, #00bfd9 , #40e9fe);
    `};

  `;
exports.BtnRegister = styled_components_1.default('button') ` 

border: none;
box-shadow: none;

padding-left:21px;

background: #0EBFD8;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 100px;

font-family: 'NotoSansSemiBold','NotoSansSemiBoldThai';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 14px;
text-align: center;
width:130px;
height:40px;
color: #FFFFFF;

cursor: pointer;

`;
exports.BtnLogin = styled_components_1.default('button') ` 

font-family:  'NotoSansSemiBold','NotoSansSemiBoldThai';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 14px;
text-align: center;
color: #3E425B;

border: none;
box-shadow: none;

padding-left:21px;

width: 130px;
height : 40px;

top: 24px;
left :958px;

background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 100px;

cursor: pointer;


`;
exports.BtnSubmitRegister = styled_components_1.default.button `

  font-family:  'NotoSansSemiBold','NotoSansSemiBoldThai';
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;

  width: 400px;
  height: 60px;
  background: #3E425B;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: white;
  cursor: pointer;

  
@media only screen and (max-width: 768px) {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  height: 50px;
}

`;
