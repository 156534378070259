"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const useStyles = styles_1.makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        boxShadow: '1px 1px 3px 0px #e0e0e0b3',
        padding: '14px',
    },
    "@media (max-width: 960px)": {
        paper: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '6px',
            marginBottom: '12px',
        },
        boxTimeCheckout: {
            display: 'flex',
            justifyCcontent: 'space-between',
        }
    },
    "@media (min-width: 960px)": {
        paper: {
            marginLeft: '6px',
            marginRight: '6px',
            marginTop: '12px',
            marginBottom: '6px',
        },
    },
    cardcontent: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    card: {
        display: 'flex',
        padding: '5px'
    },
    cardMediaLeft: {
        padding: '8px',
    },
    cardMediaCenter: {
        paddingLeft: '40px',
        paddingRight: '40px',
        textAlign: 'center'
    },
    cardMediaCenterMobile: {
        textAlign: 'right'
    },
    subject: {
        paddingTop: '5px',
        color: '#364469',
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    Divider: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
    Divider_content: {
        marginTop: '15px',
        marginBottom: '15px',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
    Address: {
        width: '70%',
        marginTop: theme.spacing(5),
        lineHeight: theme.spacing(5)
    },
    textInTable: {
        lineHeight: 1
    },
    section2: {
        margin: theme.spacing(2),
    },
    title: {
        fontSize: '20px',
        color: '#BBBBBB',
        marginBottom: '4px',
        lineHeight: '1.5'
    },
    // title: {
    //   fontSize:'20px',
    //   color: '#BBBBBB',
    //    marginBottom:'4px',
    //    lineHeight: '0.9'
    // },
    header: {
        fontSize: '20px',
        color: '#000000',
        lineHeight: '1.5'
    },
    subtitle: {
        fontSize: '20px',
        color: '#BBBBBB',
        marginBottom: '-5px'
    },
    section_discount: {
        margin: '14px',
    },
    discount_text: {
        fontSize: '21px',
        color: '#ed1c24',
        marginTop: '6px',
        marginBottom: '6px',
    },
    bg_discount: {
        backgroundColor: '#fde8e9',
        marginLeft: "-14px",
        marginRight: "-14px"
    },
    box_image: {
        paddingRight: '6px'
    },
    name: {
        fontSize: '22px',
        color: "#000000",
        fontFamily: 'DBHeaventRoundedMedv32',
        lineHeight: '0.9'
    },
    description: {
        paddingTop: '2px',
        fontSize: '18px',
        color: "#000000",
        lineHeight: '0.9'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        height: '100%',
    },
    total_label: {
        fontSize: '22px',
        fontFamily: 'DBHeaventRoundedMedv32',
        lineHeight: '0.9'
    },
    total_price: {
        fontSize: '30px',
        color: '#00bfd8',
        fontFamily: 'DBHeaventRoundedMedv32',
    },
    CompanyProfileMobile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxTimeCheckout: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));
exports.default = useStyles;
