"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatInvert = exports.isImage = exports.getDiffDate = exports.getDayTh = exports.subMonthEn = exports.subMonthTh = exports.getMonthTh = exports.formatTime = exports.formatDay = exports.formatDateYearTH = exports.formatDateYearEN = exports.getYearAndFutureTH = exports.getYearTH = exports.getFullYearTH = exports.formatDateShort = exports.formatFullYear = exports.formatYear = exports.formatsubMonth = exports.formatfullMonth = exports.formatMYForAutocomplete = exports.formatDateForAutocomplete = exports.formatDateForApi = exports.getDate = exports.formatDate = void 0;
function formatDate(date, getYear = false) {
    let data = '';
    if (getYear === true) {
        data = getDate(date) + ' ' + (date.toLocaleString('default', { month: 'short' })) + ' ' + (date.getFullYear() + 543);
    }
    else {
        data = getDate(date) + ' ' + (date.toLocaleString('default', { month: 'short' }));
    }
    return data;
}
exports.formatDate = formatDate;
function getDate(data) {
    return (data.getDate() < 10 ? ('0' + data.getDate()) : data.getDate());
}
exports.getDate = getDate;
function formatDateForApi(data) {
    const date = data.getFullYear() + '-' + (data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1) + '-' + (data.getDate() < 10 ? ('0' + data.getDate()) : data.getDate());
    return date;
}
exports.formatDateForApi = formatDateForApi;
function formatDateForAutocomplete(data) {
    const date = getDayTh(data.getDay()) + '. ' + getDate(data) + ' ' + subMonthTh(data.getMonth()) + ' ' + getYearTH(data.getFullYear());
    return date;
}
exports.formatDateForAutocomplete = formatDateForAutocomplete;
function formatMYForAutocomplete(data) {
    let date;
    date = subMonthTh(data.getMonth()) + ' ' + getFullYearTH(data.getFullYear());
    if (date === false) {
        return '-';
    }
    return date;
}
exports.formatMYForAutocomplete = formatMYForAutocomplete;
function formatfullMonth(data) {
    const date = getMonthTh(data.getMonth());
    return date;
}
exports.formatfullMonth = formatfullMonth;
function formatsubMonth(data) {
    const date = subMonthTh(data.getMonth());
    return date;
}
exports.formatsubMonth = formatsubMonth;
function formatYear(data) {
    const date = getYearTH(data.getFullYear());
    return date;
}
exports.formatYear = formatYear;
function formatFullYear(data) {
    const date = getFullYearTH(data.getFullYear());
    return date;
}
exports.formatFullYear = formatFullYear;
function formatDateShort(data) {
    const date = data.getFullYear() + '-' + (data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1)
        : data.getMonth() + 1) + '-' + (data.getDate() + 1 < 10 ? ('0' + data.getDate()) : data.getDate());
    return date;
}
exports.formatDateShort = formatDateShort;
function getFullYearTH(year) {
    const newYears = parseInt(year, undefined) + 543;
    return newYears || year;
}
exports.getFullYearTH = getFullYearTH;
function getYearTH(year) {
    const Year = parseInt(year, undefined) + 543;
    let newYears = '' + Year;
    newYears = newYears.substring(2, 4);
    return newYears || year;
}
exports.getYearTH = getYearTH;
function getYearAndFutureTH() {
    const year = new Date().getFullYear();
    const newYears = parseInt(year, undefined) + 543;
    const yearFuture = new Date().getFullYear() + 1;
    const newYearFuture = parseInt(yearFuture, undefined) + 543;
    return newYears + ' - ' + newYearFuture || year + '-' + yearFuture;
}
exports.getYearAndFutureTH = getYearAndFutureTH;
function formatDateYearEN(data) {
    return (addZero2digit(data.getDate()) + ' ' + subMonthEn(data.getMonth()) + ' ' + data.getFullYear() + ' / ' + addZero2digit(data.getHours()) + ':' + addZero2digit(data.getMinutes()));
}
exports.formatDateYearEN = formatDateYearEN;
function formatDateYearTH(data) {
    return (addZero2digit(data.getDate()) + ' ' + subMonthTh(data.getMonth()) + ' ' + getFullYearTH(data.getFullYear()) + ' / ' + addZero2digit(data.getHours()) + ':' + addZero2digit(data.getMinutes()));
}
exports.formatDateYearTH = formatDateYearTH;
function formatDay(data) {
    return addZero2digit(data.getDate()) + '/' + addZero2digit(data.getMonth() + 1) + '/' + data.getFullYear();
}
exports.formatDay = formatDay;
function formatTime(data) {
    return (addZero2digit(data.getHours()) + ':' + addZero2digit(data.getMinutes()) + ':' + addZero2digit(data.getSeconds()));
}
exports.formatTime = formatTime;
function getMonthTh(month) {
    const monthsTH = [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ];
    return monthsTH[month] || month;
}
exports.getMonthTh = getMonthTh;
function subMonthTh(month) {
    const monthsTH = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.'
    ];
    return monthsTH[month] || month;
}
exports.subMonthTh = subMonthTh;
function subMonthEn(month) {
    const monthsEN = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
    ];
    return monthsEN[month] || month;
}
exports.subMonthEn = subMonthEn;
function getDayTh(day) {
    const dayTH = [
        'อา',
        'จ',
        'อ',
        'พ',
        'พฤ',
        'ศ',
        'ส'
    ];
    return dayTH[day] || day;
}
exports.getDayTh = getDayTh;
function getDiffDate(startDate, endDate) {
    if (startDate && endDate) {
        const Difference_In_Time = endDate.getTime() - startDate.getTime();
        return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    }
    else {
        return 0;
    }
}
exports.getDiffDate = getDiffDate;
function addZero2digit(val) {
    return (val < 10) ? '0' + val : val;
}
function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
            //etc
            return true;
    }
    return false;
}
exports.isImage = isImage;
function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}
function formatInvert(date) {
    const dateArr = date.split("-");
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
}
exports.formatInvert = formatInvert;
