"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class UIStore {
    constructor(rootStore) {
        this.isServer = typeof window == "undefined";
        this.isLoading = false;
        this.isPageLoading = false;
        this.openModal = false;
        this.openText = false;
        this.rootStore = rootStore;
    }
    OnModalShow() {
        this.openModal = true;
    }
    OnModalClose() {
        this.openModal = false;
        this.openText = false;
    }
    OnTextClick() {
        this.openText = true;
    }
}
__decorate([
    mobx_1.observable
], UIStore.prototype, "isLoading", void 0);
__decorate([
    mobx_1.observable
], UIStore.prototype, "isPageLoading", void 0);
__decorate([
    mobx_1.observable
], UIStore.prototype, "openModal", void 0);
__decorate([
    mobx_1.observable
], UIStore.prototype, "openText", void 0);
exports.default = UIStore;
