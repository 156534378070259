"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BtnSubmit = exports.ContainerLink = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const react_animations_1 = require("react-animations");
// const bounceAnimation = keyframes`${bounce}`;
//const bounceAnimation = keyframes`${bounceIn}`;
// const bounceAnimation = keyframes`${fadeInUp}`;
const bounceAnimation = styled_components_1.keyframes `${react_animations_1.fadeOut}`;
exports.ContainerLink = styled_components_1.default.div `

position: fixed;
top: 50%;
left: 50%;

transform: translate(-50%, -50%);

img {
  // animation: 1s ${bounceAnimation};
}

.loading {
  margin-left:66px;
  margin-top:2px;
  font-size:18px;
  font-family: "DBHeaventRounded";
  color: #212121;
  animation: 1s ${bounceAnimation};
}

`;
exports.BtnSubmit = styled_components_1.default('button') ` 

padding: 8px 44px;
min-width: 64px;
font-size:22px;
border: none;
box-shadow: none;
border-radius: 4px;  
color:white; 
margin-right:35px;
background-image: linear-gradient(45deg, #00bfd9 , #40e9fe);  
cursor: pointer;

@media only screen and (max-width: 480px) {
  padding: 8px 26px;
  margin-left:9px;
  margin-right:9px; 
  font-size:21px;
 
}
`;
const BouncyDiv = styled_components_1.default.div `
  animation: 1s ${bounceAnimation};
`;
