"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const style = styles_1.makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    appBar: {
        position: 'relative',
    },
    instruction2: {
        fontFamily: "DBHeaventRoundedMedv32"
    },
    paper2: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px"
    },
    thaiqr: {
        border: "1px solid #02416d",
        textAlign: 'center',
        width: '230px',
        height: '398px'
    },
    header: {
        background: '#113566',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    img: {
        // width:'140px',
        height: '60px'
    },
    promptpay: {
        paddingTop: '10px',
    },
    paymentto: {
        marginBottom: '-10px',
    },
    mr: {
        marginRight: '2px',
    }
}));
exports.default = style;
