"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPMethodEnum = void 0;
var HTTPMethodEnum;
(function (HTTPMethodEnum) {
    HTTPMethodEnum["GET"] = "GET";
    HTTPMethodEnum["POST"] = "POST";
    HTTPMethodEnum["PUT"] = "PUT";
    HTTPMethodEnum["DELETE"] = "DELETE";
})(HTTPMethodEnum = exports.HTTPMethodEnum || (exports.HTTPMethodEnum = {}));
