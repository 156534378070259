"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const isClient = typeof window === "object";
const index = (props) => {
    // <script 
    // src="http://localhost:3000/sabuytag.js?public_key=7746ecaa-c548-42de-9e46-8aa43a2ebea4&amount=8000.00&order_ref=110005332264&time_expire=200&lang_code=th&redirect_url=https://www.thaitravelcenter.com/th" >      
    // </script>
    if (isClient) {
        const myTimeout = setTimeout(myGreeting, 5000);
        function myGreeting() {
            document.getElementById("demo").innerHTML = "Happy Birthday!";
        }
    }
    // function myStopFunction() {
    //   clearTimeout(myTimeout);
    // }
    react_1.useEffect(() => {
        //Runs only on the first render
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "container" },
            react_1.default.createElement("link", { rel: "stylesheet", href: "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" }),
            react_1.default.createElement("script", { src: "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" }),
            react_1.default.createElement("script", { src: "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js" }),
            react_1.default.createElement("link", { rel: "stylesheet", href: "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" }),
            react_1.default.createElement("link", { rel: "stylesheet", type: "text/css", href: "http://localhost:3000/sabuy-tags.css" }),
            react_1.default.createElement("div", { className: "container" },
                react_1.default.createElement("h2", null, "Merchant Channel"),
                react_1.default.createElement("div", { className: "form-group" },
                    react_1.default.createElement("div", { className: "col-sm-12" },
                        react_1.default.createElement("div", { id: "payment_form" }),
                        react_1.default.createElement("div", { id: "demo" }))),
                react_1.default.createElement("script", { src: "http://localhost:3000/sabuytag.js?public_key=7746ecaa-c548-42de-9e46-8aa43a2ebea4&amount=8000.00&order_ref=110005332264&time_expire=200&lang_code=th&redirect_url=https://www.thaitravelcenter.com/th" }),
                react_1.default.createElement("script", { src: "http://localhost:3000/sabuy-tags.js" })))));
};
exports.default = index;
